import { HttpErrorResponse } from "@angular/common/http";

/**
 * Util used to instantiate a material snack bar component to display error message
 **/
export class ErrorMessageUtil {
  constructor() {}

  public static getErrorMessage(error: HttpErrorResponse): string {
    if (error.error && error.error.errorCode === "GROOT_0001") {
      return "Trop de résultats, veuillez affiner votre recherche.";
    }
    if (error.status > 499 && error.status < 600) {
      return `
        Une erreur imprévue du serveur est survenue. <br><br> ${error.status} ${error.statusText}
        <br><br>
        Pour de l'aide,
        <a class="sol-text-link" href="http://cad.unige.ch" target="_blank" rel="noopener">
        contactez le CAD</a>`;
    }

    // To detect session timeout, error status 0 is used for Shibboleth and error status 401 for
    // OIDC (for OIDC, the error status is configured in the application Apache config with the
    // OIDCUnAuthAction
    if (error.status === 0 || error.status === 401) {
      return `
        Votre session a expiré. Veuillez recharger votre page.`;
    }
    if (error.status === 412) {
      if (error.error && error.error.optimisticLock === true) {
        return `
        La donnée que vous essayez de modifier a été modifiée ou supprimée par une autre personne.
        Veuillez réessayer`;
      }
      if (error.error && error.error.optimisticLock === false) {
        return `
        La donnée que vous essayez de modifier est utilisée par une application tierce.
        Veuillez réessayer plus tard.`;
      } else {
        return `
        Cette donnée ne peut être modifiée.
        Veuillez réessayer`;
      }
    }
    if (error.status === 403) {
      return `
        Votre accès n'est pas autorisé</br></br>
        Pour de l'aide
        <a class="sol-text-link" href="http://cad.unige.ch" target="_blank" rel="noopener">
        contactez le CAD</a>`;
    }
    return `
        Une erreur imprévue est survenue.</br>
        Erreur ${error.status} ${error.statusText}</br></br>
        Pour de l'aide,
        <a class="sol-text-link" href="http://cad.unige.ch" target="_blank" rel="noopener">
        contactez le CAD</a>`;
  }
}
