import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { UpdateFormValue } from "@ngxs/form-plugin";
import { Select, Store } from "@ngxs/store";
import { SolBaseComponent } from "common-ng";
import { Observable, zip } from "rxjs";
import { concatMap, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { AcademicalYear } from "../../../../shared/models/academical-year.model";
import { Faculty } from "../../../../shared/models/faculty.model";
import { SearchForm, SearchFormDefinition } from "../../../../shared/models/search-form.model";
import { AcademicalYearService } from "../../../../shared/services/academical-year.service";
import { GetFaculties } from "../../../../shared/stores/search/search.action";
import { SearchState } from "./../../../../shared/stores/search/search.state";

@Component({
  selector: "app-academical-year-container",
  templateUrl: "./academical-year.container.html",
  styleUrls: ["./academical-year.container.scss"]
})
export class AcademicalYearContainer extends SolBaseComponent implements OnInit {
  @Select(SearchState.getFormModel) searchFormObs: Observable<SearchForm>;
  academicalYearForm: UntypedFormGroup;
  formDefinition: SearchFormDefinition = new SearchFormDefinition();
  academicalYears: AcademicalYear[];
  currentAcademicalYear: AcademicalYear;
  isLoadingAcademicalYears: boolean = true;

  @Input()
  compact: Boolean;

  @Output() academicalYearChanged: EventEmitter<void> = new EventEmitter();
  _isUserAction: boolean = false;

  isOpen: boolean = false;

  constructor(
    private _store: Store,
    private _formBuilder: UntypedFormBuilder,
    private _academicalYearService: AcademicalYearService,
    private _router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.initNewForm();
    this._observeChanges();
    this._fetchInitialFormDataObs().subscribe(() => {
      this._isUserAction = false; // set flag before fetch
      this._fetchFormDataOnFormInputChange();
    });
  }

  public initNewForm(): void {
    this.academicalYearForm = this._formBuilder.group({
      [this.formDefinition.academicalYear]: []
    });
  }

  private _observeChanges(): void {
    this.searchFormObs.pipe(distinctUntilChanged()).subscribe(searchForm => {
      if (
        searchForm.academicalYear !==
        this.academicalYearForm.get(this.formDefinition.academicalYear).value
      ) {
        this.academicalYearForm
          .get(this.formDefinition.academicalYear)
          .setValue(searchForm.academicalYear);
      }
      if (this._isUserAction && this._router.url.indexOf("/my-schedule") > -1) {
        this.academicalYearChanged.emit();
      }
    });
  }

  private _getCurrentAcademicalYear(): AcademicalYear | undefined {
    return this.academicalYears.find(academicalYear => academicalYear.current === true);
  }

  private _fetchInitialFormDataObs(): Observable<Faculty[]> {
    const formAcademicalYearInput = this._getSelectedAcademicalYear();
    return this._academicalYearService.getAll().pipe(
      takeUntil(this.unsubscribe$),
      concatMap((academicalYears: AcademicalYear[]) => {
        this.academicalYears = academicalYears;
        this.currentAcademicalYear = this._getCurrentAcademicalYear();
        let selectedYear = this.currentAcademicalYear.entityId;
        if (formAcademicalYearInput) {
          selectedYear = formAcademicalYearInput;
        }
        this.academicalYearForm.get(this.formDefinition.academicalYear).setValue(selectedYear);
        this.isLoadingAcademicalYears = false;
        const year = academicalYears.find(
          academicalYear => academicalYear.entityId === selectedYear
        );

        this._updateStore();

        const facultiesObs = this._store.dispatch(
          new GetFaculties({ academicalYear: selectedYear })
        );

        return zip(facultiesObs);
      })
    );
  }

  private _fetchFormDataOnFormInputChange(): void {
    this.academicalYearForm.controls[this.formDefinition.academicalYear].valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this._isUserAction = true;
        this._updateStore();
        this._store.dispatch(new GetFaculties({ academicalYear: data }));
      });
  }

  private _updateStore(): void {
    const oldSearchForm = this._store.selectSnapshot(SearchState.getFormModel);
    const searchForm = Object.assign(new SearchForm(), oldSearchForm);
    searchForm.academicalYear = this.academicalYearForm.get(
      this.formDefinition.academicalYear
    ).value;
    if (Number(searchForm.academicalYear) < 2023) {
      searchForm.recorded = null;
      searchForm.remoteOnly = null;
    }
    if (JSON.stringify(oldSearchForm) !== JSON.stringify(searchForm)) {
      this._store.dispatch(
        new UpdateFormValue({
          path: "search.form",
          value: searchForm
        })
      );
    }
  }

  private _getSelectedAcademicalYear(): string {
    return this.academicalYearForm.get(this.formDefinition.academicalYear).value;
  }

  format(academicalYear: AcademicalYear): string {
    return "Année académique " + academicalYear.label.split(" ").pop();
  }
}
