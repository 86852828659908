import { Location } from "@angular/common";
import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatAccordion } from "@angular/material/expansion";
import { ActivatedRoute, Router, RouterStateSnapshot } from "@angular/router";
import { UpdateFormValue } from "@ngxs/form-plugin";
import { RouterState } from "@ngxs/router-plugin";
import { Select, Store } from "@ngxs/store";
import { SolBaseComponent } from "common-ng";
import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { SearchTypeEnum } from "../../../../shared/enums/search-type.enum";
import { SelectOptionEnum } from "../../../../shared/enums/select-option.enum";
import { Faculty } from "../../../../shared/models/faculty.model";
import { SearchForm, SearchFormDefinition } from "../../../../shared/models/search-form.model";
import { StudyPlanDepartment } from "../../../../shared/models/study-plan-department.model";
import { Teacher } from "../../../../shared/models/teacher.model";
import { TeachingDepartment } from "../../../../shared/models/teaching-department.model";
import {
  GetStudyLevels,
  GetStudyPlanDepartments,
  GetTeachingDepartments
} from "../../../../shared/stores/search/search.action";
import { SearchState } from "../../../../shared/stores/search/search.state";

@Component({
  selector: "app-search-bar-container",
  templateUrl: "./search-bar.container.html",
  styleUrls: ["./search-bar.container.scss"]
})
export class SearchBarContainer extends SolBaseComponent implements OnInit {
  private readonly _onSubmitEventEmitter: EventEmitter<undefined> = new EventEmitter<undefined>();
  form: UntypedFormGroup;
  formDefinition: SearchFormDefinition = new SearchFormDefinition();
  teachers: Teacher[];
  isLoadingTeachers: boolean = false;
  _activeSearchType: SearchTypeEnum | undefined;
  oldValueForSearchTerm: string;
  fetchedAcademicalYear: string;
  fetchedFacultyId: string;
  @ViewChild("faculties") faculties: MatAccordion;
  isDisabled: boolean = true;

  @Select(SearchState.getActiveSearchType) activeSearchTypeObs: Observable<SearchTypeEnum>;
  @Select(SearchState.getFaculties) facultiesObs: Observable<Faculty[]>;
  @Select(SearchState.getStudyPlanDepartments) studyPlanDepartmentsObs: Observable<
    StudyPlanDepartment[]
  >;
  @Select(SearchState.getTeachingDepartments) teachingDepartmentsObs: Observable<
    TeachingDepartment[]
  >;
  @Select(RouterState.state) routerStateObs: Observable<RouterStateSnapshot>;
  @Select(SearchState.getFormModel) searchFormObs: Observable<SearchForm>;

  get searchTypeEnum(): typeof SearchTypeEnum {
    return SearchTypeEnum;
  }

  get selectOptionEnum(): typeof SelectOptionEnum {
    return SelectOptionEnum;
  }

  @Output("onSubmit")
  readonly onSubmitObs: Observable<undefined> = this._onSubmitEventEmitter.asObservable();

  constructor(
    private _store: Store,
    private _formBuilder: UntypedFormBuilder,
    private _route: ActivatedRoute,
    private _location: Location,
    private _router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.initNewForm();
    this._observeChanges();
  }

  public initNewForm(): void {
    this.form = this._formBuilder.group({
      [this.formDefinition.searchTerm]: [],
      [this.formDefinition.facultyId]: [],
      [this.formDefinition.teachingDepartmentId]: [],
      [this.formDefinition.studyPlanDepartmentId]: []
    });
  }

  public onSubmit(): void {
    this._onSubmitEventEmitter.emit();
    if (this._router.url.startsWith("/home")) {
      this._router.navigate(["teachings"]);
    } else if (this._router.url.startsWith("/study-plans/details")) {
      this._router.navigate(["study-plans"]);
    } else {
      this._updateStore();
    }
  }

  public openFaculty(): void {
    this.faculties.closeAll();
  }

  public clearSearch(): void {
    this.form.get(this.formDefinition.searchTerm).setValue("");
  }

  public isActiveSearchType(searchType: SearchTypeEnum): boolean {
    if (
      this.form.get(this.formDefinition.facultyId).value &&
      this.form.get(this.formDefinition.facultyId).value !== "default-value"
    ) {
      return searchType === this._activeSearchType;
    } else {
      return false;
    }
  }

  private _observeChanges(): void {
    this.activeSearchTypeObs.pipe(takeUntil(this.unsubscribe$)).subscribe(searchType => {
      this._activeSearchType = searchType;
    });
    this.form.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this._updateDisabled();
      this._updateStore();
    });

    this.form.controls[this.formDefinition.facultyId].valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        switch (this._activeSearchType) {
          case SearchTypeEnum.teachings:
            this.form.get(this.formDefinition.teachingDepartmentId).setValue(undefined);
            break;
          case SearchTypeEnum.studyPlans:
            this.form.get(this.formDefinition.studyPlanDepartmentId).setValue(undefined);
            break;
        }
        this._fetchData();
      });

    this.searchFormObs.pipe(distinctUntilChanged()).subscribe(searchForm => {
      if (searchForm.facultyId !== this.form.get(this.formDefinition.facultyId).value) {
        this.form.get(this.formDefinition.facultyId).setValue(searchForm.facultyId);
      }

      switch (this._activeSearchType) {
        case SearchTypeEnum.teachings:
          if (
            searchForm.departmentId !==
            this.form.get(this.formDefinition.teachingDepartmentId).value
          ) {
            this.form
              .get(this.formDefinition.teachingDepartmentId)
              .setValue(searchForm.departmentId);
          }
          break;
        case SearchTypeEnum.studyPlans:
          if (
            searchForm.departmentId !==
            this.form.get(this.formDefinition.studyPlanDepartmentId).value
          ) {
            this.form
              .get(this.formDefinition.studyPlanDepartmentId)
              .setValue(searchForm.departmentId);
          }
          break;
      }

      if (searchForm.searchTerm !== this.form.get(this.formDefinition.searchTerm).value) {
        this.form.get(this.formDefinition.searchTerm).setValue(searchForm.searchTerm);
      }

      this._updateDisabled();
      this._fetchData();
    });
  }

  private _updateDisabled(): void {
    this.isDisabled =
      (this.form.controls[this.formDefinition.searchTerm].value === undefined ||
        this.form.controls[this.formDefinition.searchTerm].value === "") &&
      (this.form.controls[this.formDefinition.facultyId].value === undefined ||
        this.form.controls[this.formDefinition.facultyId].value === "");
  }

  private _fetchData(): void {
    const academicalYear = this._store.selectSnapshot(SearchState.getFormModel).academicalYear;
    const facultyId = this.form.controls[this.formDefinition.facultyId].value;
    if (academicalYear === this.fetchedAcademicalYear && facultyId === this.fetchedFacultyId) {
      return;
    }
    this.fetchedAcademicalYear = academicalYear;
    this.fetchedFacultyId = facultyId;
    this._store.dispatch(
      new GetStudyPlanDepartments({
        structureId: facultyId,
        academicalYear: academicalYear
      })
    );
    this._store.dispatch(
      new GetTeachingDepartments({
        structureId: facultyId,
        academicalYear: academicalYear
      })
    );
    this._store.dispatch(
      new GetStudyLevels({
        structureId: facultyId,
        academicalYear: academicalYear
      })
    );
  }

  private _updateStore(): void {
    const oldSearchForm = this._store.selectSnapshot(SearchState.getFormModel);
    const searchForm = Object.assign(new SearchForm(), oldSearchForm);
    searchForm.facultyId =
      this.form.get(this.formDefinition.facultyId).value !== ""
        ? this.form.get(this.formDefinition.facultyId).value
        : undefined;
    searchForm.teachingDepartmentId = this.form.get(this.formDefinition.teachingDepartmentId).value;
    searchForm.studyPlanDepartmentId = this.form.get(
      this.formDefinition.studyPlanDepartmentId
    ).value;
    searchForm.searchTerm = this.form.get(this.formDefinition.searchTerm).value;

    switch (this._activeSearchType) {
      case SearchTypeEnum.teachings:
        searchForm.departmentId = searchForm.teachingDepartmentId;
        searchForm.teachingDepartmentId = undefined;
        searchForm.studyPlanDepartmentId = undefined;
        break;
      case SearchTypeEnum.studyPlans:
        searchForm.departmentId = searchForm.studyPlanDepartmentId;
        searchForm.studyPlanDepartmentId = undefined;
        searchForm.teachingDepartmentId = undefined;
        break;
    }

    searchForm.departmentId = searchForm.departmentId !== "" ? searchForm.departmentId : undefined;

    if (JSON.stringify(oldSearchForm) !== JSON.stringify(searchForm)) {
      this._store.dispatch(
        new UpdateFormValue({
          path: "search.form",
          value: searchForm
        })
      );
    }
  }
}
