import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { RouterStateSnapshot } from "@angular/router";
import { UpdateFormValue } from "@ngxs/form-plugin";
import { RouterState } from "@ngxs/router-plugin";
import { Select, Store } from "@ngxs/store";
import { SolBaseComponent } from "common-ng";
import { Observable } from "rxjs";
import { debounceTime, filter, switchMap, takeUntil, tap } from "rxjs/operators";
import { StringUtil } from "../../../../../../../ext-admin/src/app/shared/utils/string.util";
import { SearchTypeEnum } from "../../../../shared/enums/search-type.enum";
import { SelectOptionEnum } from "../../../../shared/enums/select-option.enum";
import { AcademicalYear } from "../../../../shared/models/academical-year.model";
import { SearchForm, SearchFormDefinition } from "../../../../shared/models/search-form.model";
import { StudyLevel } from "../../../../shared/models/study-level.model";
import { Teacher } from "../../../../shared/models/teacher.model";
import { TeacherService } from "../../../../shared/services/teacher.service";
import { SearchState } from "../../../../shared/stores/search/search.state";

@Component({
  selector: "app-filter-bar-container",
  templateUrl: "./filter-bar.container.html",
  styleUrls: ["./filter-bar.container.scss"]
})
export class FilterBarContainer extends SolBaseComponent implements OnInit {
  form: UntypedFormGroup;
  formDefinition: SearchFormDefinition = new SearchFormDefinition();
  teachers: Teacher[];
  isLoadingTeachers: boolean = false;
  academicalYears: AcademicalYear[];
  currentAcademicalYear: AcademicalYear;
  oldValueForSearchTerm: string;

  @Input()
  forTutorial: boolean = false;
  @Select(SearchState.getStudyLevels) studyLevelsObs: Observable<StudyLevel[]>;
  @Select(RouterState.state) routerStateObs: Observable<RouterStateSnapshot>;

  get searchTypeEnum(): typeof SearchTypeEnum {
    return SearchTypeEnum;
  }

  get selectOptionEnum(): typeof SelectOptionEnum {
    return SelectOptionEnum;
  }

  constructor(
    private _store: Store,
    private _formBuilder: UntypedFormBuilder,
    private _teacherService: TeacherService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initNewForm();
    this.form.valueChanges.pipe(debounceTime(500)).subscribe(() => this._updateStore());
    this._observeChanges();
    this._listenFieldFormChangeToGetLOV();
  }

  public initNewForm(): void {
    this.form = this._formBuilder.group({
      [this.formDefinition.studyLevelId]: [],
      [this.formDefinition.personId]: [],
      [this.formDefinition.shortPeriodicities]: [],
      [this.formDefinition.days]: [],
      [this.formDefinition.activityLanguages]: [],
      [this.formDefinition.startHour]: [],
      [this.formDefinition.endHour]: [],
      [this.formDefinition.remoteOnly]: [],
      [this.formDefinition.recorded]: []
    });
  }

  public onSubmit(): void {}

  public isActiveSearchType(searchType: SearchTypeEnum): boolean {
    const activeSearchType = this._store.selectSnapshot(SearchState.getActiveSearchType);
    return searchType === activeSearchType;
  }

  public isYearAfter2022(): boolean {
    return Number(this._store.selectSnapshot(SearchState.getFormModel).academicalYear) > 2022;
  }

  resetFormValueIfNotInteger(formControlName: string): void {
    if (!StringUtil.isInteger(this.form.value[formControlName])) {
      if (this.form.get(formControlName).value !== "") {
        this.form.get(formControlName).setValue("");
      }
    }
  }

  resetFormValue(
    formControlName: string,
    matOptions: any[],
    matAutoComplete: MatAutocomplete
  ): void {
    if (!matAutoComplete.isOpen) {
      if (!matOptions) {
        this.form.get(formControlName).setValue("");
      }
    }
  }

  displayTeacherName(personId: string): string | undefined {
    return personId ? this.teachers.find(_ => _.personId === personId).publicId : undefined;
  }

  private _listenFieldFormChangeToGetLOV(): void {
    this.form
      .get(this.formDefinition.personId)
      .valueChanges.pipe(
        debounceTime(500),
        filter(value => typeof value !== "undefined" && value !== null && value.length > 0),
        tap(() => {
          this.teachers = [];
          this.isLoadingTeachers = true;
        }),
        switchMap(value => {
          const academicalYear = this._store.selectSnapshot(
            SearchState.getFormModel
          ).academicalYear;
          return this._teacherService.getTeachers(value, academicalYear);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(users => {
        this.isLoadingTeachers = false;
        this.teachers = users;
      });
  }

  private _observeChanges(): void {
    this._store.select(SearchState.getFormModel).subscribe(searchForm => {
      if (
        searchForm.activityLanguages !== this.form.get(this.formDefinition.activityLanguages).value
      ) {
        this.form.get(this.formDefinition.activityLanguages).setValue(searchForm.activityLanguages);
      }
      if (searchForm.days !== this.form.get(this.formDefinition.days).value) {
        this.form.get(this.formDefinition.days).setValue(searchForm.days);
      }
      if (searchForm.studyLevelId !== this.form.get(this.formDefinition.studyLevelId).value) {
        this.form.get(this.formDefinition.studyLevelId).setValue(searchForm.studyLevelId);
      }
      if (searchForm.personId !== this.form.get(this.formDefinition.personId).value) {
        this.form.get(this.formDefinition.personId).setValue(searchForm.personId);
      }
      if (
        typeof searchForm.personId !== "undefined" &&
        searchForm.personId !== "" &&
        (this.teachers == null ||
          this.teachers.find(_ => _.personId === searchForm.personId) == null)
      ) {
        const academicalYear = this._store.selectSnapshot(SearchState.getFormModel).academicalYear;
        this._teacherService
          .findByPersonId(searchForm.personId, academicalYear)
          .subscribe(teachers => {
            this.isLoadingTeachers = false;
            this.teachers = teachers;
            this.form.get(this.formDefinition.personId).setValue(searchForm.personId);
          });
      }
      if (
        searchForm.shortPeriodicities !==
        this.form.get(this.formDefinition.shortPeriodicities).value
      ) {
        this.form
          .get(this.formDefinition.shortPeriodicities)
          .setValue(searchForm.shortPeriodicities);
      }
      if (searchForm.startHour !== this.form.get(this.formDefinition.startHour).value) {
        this.form.get(this.formDefinition.startHour).setValue(searchForm.startHour);
      }
      if (searchForm.endHour !== this.form.get(this.formDefinition.endHour).value) {
        this.form.get(this.formDefinition.endHour).setValue(searchForm.endHour);
      }
      if (searchForm.remoteOnly !== this.form.get(this.formDefinition.remoteOnly).value) {
        this.form.get(this.formDefinition.remoteOnly).setValue(searchForm.remoteOnly);
      }
      if (searchForm.recorded !== this.form.get(this.formDefinition.recorded).value) {
        this.form.get(this.formDefinition.recorded).setValue(searchForm.recorded);
      }
    });

    this.studyLevelsObs.pipe(takeUntil(this.unsubscribe$)).subscribe(studyLevels => {
      if (studyLevels.length > 0) {
        if (typeof this.form.get(this.formDefinition.studyLevelId).value !== "undefined") {
          const currentLevel = this.form.get(this.formDefinition.studyLevelId).value;
          const levelExists = studyLevels.filter(l => l.levelId === currentLevel).length > 0;
          if (!levelExists) {
            this.form.get(this.formDefinition.studyLevelId).setValue("");
            this._updateStore();
          }
        }
      }
    });
  }

  private _updateStore(): void {
    if (
      typeof this.form.get(this.formDefinition.personId).value !== "undefined" &&
      isNaN(this.form.get(this.formDefinition.personId).value)
    ) {
      return;
    }

    const oldSearchForm = this._store.selectSnapshot(SearchState.getFormModel);
    const searchForm = Object.assign(new SearchForm(), oldSearchForm);
    searchForm.personId = this.form.get(this.formDefinition.personId).value;
    if (searchForm.personId === "") {
      searchForm.personId = undefined;
    }
    searchForm.studyLevelId = this.form.get(this.formDefinition.studyLevelId).value;
    searchForm.shortPeriodicities = this.form.get(this.formDefinition.shortPeriodicities).value;
    searchForm.days = this.form.get(this.formDefinition.days).value;
    searchForm.activityLanguages = this.form.get(this.formDefinition.activityLanguages).value;
    searchForm.startHour = this.form.get(this.formDefinition.startHour).value;
    searchForm.endHour = this.form.get(this.formDefinition.endHour).value;
    searchForm.remoteOnly = this.form.get(this.formDefinition.remoteOnly).value;
    searchForm.recorded = this.form.get(this.formDefinition.recorded).value;

    if (
      JSON.stringify(oldSearchForm) !== "{}" &&
      JSON.stringify(oldSearchForm) !== JSON.stringify(searchForm)
    ) {
      this._store.dispatch(
        new UpdateFormValue({
          path: "search.form",
          value: searchForm
        })
      );
    }
  }
}
